import * as React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Button, Label, Radio, SingleDatePicker } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, Window, VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { RecurringDecisionTestId } from './RecurringDecision.testIds'

export type Recurring = 'override' | 'following' | 'all'
export type RecurringDecisionMode = 'edit' | 'delete'

export interface RecurringDecisionProps {
  active: boolean
  name?: string
  selectedDate: Date
  mode?: RecurringDecisionMode
  isSelectedDayToday: boolean
  setIsActive: (value: boolean) => void
  onSuccess: (decision: Recurring, onSuccess?: Date) => void
  setSelectedDate: (date: Date) => void
}

export function RecurringDecision(props: RecurringDecisionProps) {
  const { formatMessage } = useLocales()
  const { selectedDate, mode, setSelectedDate, name = '' } = props
  const [decision, setDecision] = React.useState<Recurring>('all')
  const formattedDate = DateOnly.fromDate(selectedDate).formatNYearNMonthNDayFWeek()
  const isEdit = mode === 'edit'

  return (
    <Window active={props.active}>
      <Modal ariaLabel="main" data-test={RecurringDecisionTestId.modal}>
        <ModalHeader
          onClose={e => {
            e.preventDefault()
            props.setIsActive(false)
          }}
        >
          <ModalTitle title={formatMessage(accessRulesMessages.decisionModeTitle, { isEdit: mode === 'edit', name })} />
        </ModalHeader>

        <ModalBody>
          <VStack spacing="s">
            <Box>
              <Radio
                value="all"
                checked={decision === 'all'}
                description={formatMessage(accessRulesMessages.decisionModeFollowingDescription, {
                  name,
                  isEdit,
                  date: formattedDate,
                })}
                onChange={() => setDecision('all')}
              >
                {formatMessage(accessRulesMessages.decisionModeAllDescription, { isEdit, name })}
              </Radio>
            </Box>

            <VStack>
              <Radio
                value="following"
                checked={decision === 'following'}
                description={formatMessage(accessRulesMessages.decisionModeFollowingDescription, {
                  name,
                  isEdit,
                  date: formattedDate,
                })}
                onChange={() => setDecision('following')}
              >
                {formatMessage(accessRulesMessages.decisionModeFollowingLabel, { isEdit })}
              </Radio>
              {decision === 'following' && (
                <Box width="50%" mt="s" ml="l" mb="s">
                  <Label primary={formatMessage(accessRulesMessages.specificDate)}>
                    <SingleDatePicker
                      zIndexLayer="modal"
                      id="access-rule-following-start-date"
                      value={selectedDate}
                      onChange={selectedDate => selectedDate && setSelectedDate(selectedDate)}
                      showClearDate
                    />
                  </Label>
                </Box>
              )}
            </VStack>
            <VStack>
              <Radio
                value="override"
                checked={decision === 'override'}
                description={formatMessage(accessRulesMessages.decisionModeOverrideDescription, { name })}
                onChange={() => setDecision('override')}
              >
                {formatMessage(accessRulesMessages.decisionModeOverrideLabel, { isEdit })}
              </Radio>
              {decision === 'override' && (
                <Box width="50%" mt="s" ml="l" mb="s">
                  <Label primary={formatMessage(accessRulesMessages.specificDate)}>
                    <SingleDatePicker
                      zIndexLayer="modal"
                      id="access-rule-overide-start-date"
                      value={selectedDate}
                      onChange={selectedDate => selectedDate && setSelectedDate(selectedDate)}
                      showClearDate
                    />
                  </Label>
                </Box>
              )}
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ModalActions>
            <Button
              data-test={RecurringDecisionTestId.cancel}
              variant="secondary"
              onClick={e => {
                e.preventDefault()
                props.setIsActive(false)
              }}
            >
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              data-test={RecurringDecisionTestId.continue}
              variant="primary"
              onClick={e => {
                e.preventDefault()
                props.onSuccess(decision, selectedDate)
              }}
            >
              {formatMessage(commonMessages.continue)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
