import { useMemo } from 'react'
import {
  type PDRTimeSlots,
  ExperienceStatusEnum,
  type Experience,
  type ExperienceStatus,
  type PDRAmenitiesEnumOption,
  type PDRRoomTypeEnumOption,
  type PDRUnitOfMeasurementEnumOption,
  type HeroImage,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { formSchema, useImageForm } from '@sevenrooms/core/ui-kit/form'

export const useSpaceForm = () => {
  const image = useImageForm()
  const minimumSpendMatrix = useMinimumSpendMatrixForm()
  return useMemo(
    () =>
      z.object({
        status: z.custom<ExperienceStatus>(),
        name: z.string().trim().min(1),
        description: z.string().trim().min(1),
        heroImage: image.nullable(),
        imageList: z.array(image.nullable()),
        pdrMaxSeatedCapacity: z.number().min(1).nullable(),
        pdrMinSeatedCapacity: z.number().nullable(),
        pdrMaxStandingCapacity: z.number().nullable(),
        pdrMinStandingCapacity: z.number().nullable(),
        pdrRoomType: z.custom<PDRRoomTypeEnumOption>().nullable(),
        pdrSquareFootage: z.number().nullable(),
        pdrUnitOfMeasurement: z.custom<PDRUnitOfMeasurementEnumOption>().nullable(),
        pdrAmenityList: z.custom<PDRAmenitiesEnumOption[]>(),
        menu: formSchema,
        pdrAdminFee: z.number().nullable(),
        pdrTaxRateId: z.string().nullable(),
        pdrMinSpend: z.number().nullable(),
        pdrMinimumSpendMatrix: z.array(minimumSpendMatrix),
        pdrTripleSeatRoomId: z.string().nullable(),
        pdrDepositFee: z.number().nullable(),
        unlinkedAccessRuleIDs: z.array(z.string()),
        linkedAccessRuleIDs: z.array(z.string()),
      }),
    [image, minimumSpendMatrix]
  )
}

export type SpaceFormType = ZodSchema<typeof useSpaceForm>

const useMinimumSpendMatrixForm = () =>
  useMemo(
    () =>
      z.object({
        startDay: z.string(),
        endDay: z.string(),
        startTime: z.custom<PDRTimeSlots>(),
        endTime: z.custom<PDRTimeSlots>(),
        minimumSpend: z.number(),
      }),
    []
  )

export function getDefaultValues(experience?: Experience) {
  if (experience) {
    return {
      status: experience.status,
      name: experience.name,
      description: experience.description,
      heroImage: experience.heroImage?.rawUrlKey ? getHeroImageForm(experience.heroImage) : null,
      imageList: experience.imageList?.map(getHeroImageForm) ?? [],
      pdrMaxSeatedCapacity: experience.pdrMaxSeatedCapacity,
      pdrMinSeatedCapacity: experience.pdrMinSeatedCapacity,
      pdrMaxStandingCapacity: experience.pdrStandingCapacity,
      pdrMinStandingCapacity: experience.pdrStandingMinCapacity ?? null,
      pdrRoomType: experience.pdrRoomType,
      pdrSquareFootage: experience.pdrSquareFootage,
      pdrUnitOfMeasurement: experience.pdrUnitOfMeasurement,
      pdrAmenityList: experience.pdrAmenityList,
      menu: experience.menuBlobId ? { rawUrl: experience.menuBlobId, name: experience.menuFilename } : { rawUrl: '', name: '' },
      pdrAdminFee: experience.pdrAdminFee,
      pdrTaxRateId: experience.pdrTaxRateId,
      pdrMinSpend: experience.pdrMinSpend,
      pdrMinimumSpendMatrix: experience.pdrMinimumSpendMatrix,
      pdrTripleSeatRoomId: experience.pdrTripleSeatRoomId,
      pdrDepositFee: experience.pdrDepositFee,
      unlinkedAccessRuleIDs: [],
      linkedAccessRuleIDs: [],
    } as SpaceFormType
  }
  return {
    status: ExperienceStatusEnum.DRAFT,
    name: '',
    description: '',
    heroImage: null,
    imageList: [],
    pdrMaxSeatedCapacity: 0,
    pdrMinSeatedCapacity: null,
    pdrMaxStandingCapacity: null,
    pdrMinStandingCapacity: null,
    pdrRoomType: null,
    pdrSquareFootage: null,
    pdrUnitOfMeasurement: null,
    pdrAmenityList: [],
    menu: null,
    pdrAdminFee: null,
    pdrTaxRateId: null,
    pdrMinSpend: null,
    pdrMinimumSpendMatrix: [],
    pdrTripleSeatRoomId: null,
    pdrDepositFee: null,
    unlinkedAccessRuleIDs: [],
    linkedAccessRuleIDs: [],
  } as SpaceFormType
}

function getHeroImageForm(image: HeroImage) {
  return {
    name: ' ',
    rawUrl: `/.h/download/${image.rawUrlKey}`,
    crop: image.cropInfo,
  }
}
