import { TransitionBlockerWithOldRouterSupport } from 'mgr/lib/components/TransitionBlockerWithOldRouterSupport'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState, type ForwardedRef, type PropsWithChildren } from 'react'
import {
  useCreateAccessRuleMutation,
  useSaveAccessRuleMutation,
  useDeleteAccessRuleMutation,
  type AccessRuleInput,
  type AccessRulesCreateSaveArgs,
} from '@sevenrooms/core/api'
import type { AccessRule, ShiftCategory } from '@sevenrooms/core/domain'
import { useForm, FormProvider } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, DateTime } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Window, UnsavedChangesModal, Loader, notify, SlideOut, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { OverridesModal, ReviewChangesModal } from '@sevenrooms/core/ui-kit/vx-layout'
import { useLazyGetAccessRuleOverridesQuery } from '@sevenrooms/lib-override-series-list'
import { ActivityLogModal } from '@sevenrooms/mgr-activity-log/components/ActivityLogModal'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { getInitialState, getAccessRuleDefaults, useAccessRuleForm, type AccessRuleForm } from './AccessRule.zod'
import { AccessRuleChanges } from './AccessRuleChanges'
import { AccessRuleOverrides } from './AccessRuleOverrides'
import { accessRulesMessages } from './AccessRules.locales'
import { AccessRuleTestId } from './AccessRules.testIds'
import { getInitialBookingChannels } from './components/BookingChannels/BookingChannels.zod'
import { getInitialBookingWindow } from './components/BookingWindow/BookingWindow.zod'
import { ConfirmationDialog } from './components/ConfirmationDialog'
import { EditMode } from './components/EditMode'
import { RecurringDecision, type RecurringDecisionMode, type Recurring } from './components/RecurringDecision'
import { AccessRuleSlideoutFooter, AccessRuleContextProvider, type AccessRuleContext } from './components/shared'
import { ViewMode } from './components/ViewMode'
import { toAccessRuleInput, formatDate } from './toAccessRuleInput'
import { areObjectsEqual, getClonedDateRange, getOverlappingShifts } from './utils'

export interface AccessRulesSlideoutParams extends AccessRulesCreateSaveArgs {
  date: string
}

export interface AccessRulesSlideoutFormProps {
  context: Omit<AccessRuleContext, 'shifts'>
  params: AccessRulesSlideoutParams
  startDate: Date
  startTimeDisplay?: string
  endTimeDisplay?: string
  shiftCategories: ShiftCategory[]
  onClose: () => void
  onRuleSave: (accessRule: AccessRule) => void
  onRuleDelete: () => void
  mode?: 'new-item'
  disabledFields?: string[]

  // Used by Perks to override regular onRuleSave
  onRuleSaveOverride?: ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }) => void
}

export interface AccessRulesSlideoutRef {
  onBeforeClose: () => boolean
}

export const AccessRulesSlideoutForm = forwardRef<AccessRulesSlideoutRef, PropsWithChildren<AccessRulesSlideoutFormProps>>(
  AccessRulesSlideoutFormComponent
)

function AccessRulesSlideoutFormComponent(
  props: PropsWithChildren<AccessRulesSlideoutFormProps>,
  ref: ForwardedRef<AccessRulesSlideoutRef>
) {
  const {
    context,
    startDate: initialStartDate,
    startTimeDisplay,
    endTimeDisplay,
    shiftCategories: initialShiftCategories,
    params,
    children,
    onClose,
    onRuleSave,
    onRuleDelete,
    mode,
    disabledFields,
  } = props
  const { formatMessage } = useLocales()
  const { venueSettings, venueCurrencyCode } = useAppContext()
  const accessRuleDefaults = getAccessRuleDefaults(context)

  const [createAccessRule, { isLoading: isCreating }] = useCreateAccessRuleMutation()
  const [saveAccessRule, { isLoading: isSaving }] = useSaveAccessRuleMutation()
  const [deleteAccessRule, { isLoading: isDeleting }] = useDeleteAccessRuleMutation()
  const [getBookingAccessOverrideList, bookingAccessOverrideList] = useLazyGetAccessRuleOverridesQuery()
  const isMutating = isCreating || isSaving || isDeleting

  const [decisionMode, setDecisionMode] = useState<RecurringDecisionMode | undefined>(undefined)
  const [decision, setDecision] = useState<Recurring | undefined>(undefined)
  const [accessRuleClone, setAccessRuleClone] = useState<AccessRule | undefined>(undefined)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false)
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false)
  const [isReviewChangesModalOpen, setIsReviewChangesModalOpen] = useState(false)
  const [arFormData, setARFormData] = useState<AccessRuleForm | undefined>(undefined)
  const [arResponse, setARResponse] = useState<AccessRule | undefined>(undefined)
  const [isActivityLogsActive, setIsActivityLogsActive] = useState(false)
  const [hasChangedFromOriginal, setHasChangedFromOriginal] = useState(false)

  const shouldOverride = context.accessRule?.isOverride
  const associatedExclusiveAccessPerkId = context.accessRule?.exclusiveAccessPerkId
  const isSameDay = context.accessRule?.startDate?.formatNYearNMonthNDay() === context.accessRule?.endDate?.formatNYearNMonthNDay()
  const isEditOpen = props.mode === 'new-item' || (decision != null && decisionMode !== 'delete') || (decisionMode === 'edit' && isSameDay)
  const [isSelectedDayToday, selectedDateOnly] = useMemo(
    () => [formatDate(new Date()) === formatDate(new Date(params.date)), DateOnly.from(params.date)] as const,
    [params.date]
  )
  const [selectedDate, setSelectedDate] = useState<Date>(selectedDateOnly.toJsDate())
  const getOverrideName = useCallback(
    () => `${context.accessRule?.name} ${DateOnly.fromDate(selectedDate).formatNYearNMonthNDay()}`,
    [context.accessRule?.name, selectedDate]
  )

  const name = useMemo(
    () => (decision === 'override' && !shouldOverride ? getOverrideName() : context.accessRule?.name ?? ''),
    [decision, shouldOverride, getOverrideName, context.accessRule?.name]
  )

  const defaultValues = useMemo(
    () =>
      getInitialState({
        ...context,
        accessRule: accessRuleClone ?? context.accessRule,
        startDate: initialStartDate,
        startTimeDisplay,
        endTimeDisplay,
        shiftCategories: initialShiftCategories,
        name,
        editPhoto: formatMessage(accessRulesMessages.editPhoto),
        startOfDayTime: venueSettings.startOfDayTime,
        currencyCode: venueCurrencyCode,
        accessRuleDefaults,
        selectedDate,
      }),
    [
      context,
      accessRuleClone,
      initialStartDate,
      startTimeDisplay,
      endTimeDisplay,
      initialShiftCategories,
      name,
      formatMessage,
      venueSettings.startOfDayTime,
      venueCurrencyCode,
      accessRuleDefaults,
      selectedDate,
    ]
  )
  const schema = useAccessRuleForm()
  const { field, ...formMethods } = useForm(schema, { defaultValues })
  const { handleSubmit, watch, getValues } = formMethods

  const {
    dateRange: { startDate, endDate, isInfinite },
    accessTimeType,
    selectedDays,
    restrictToShifts,
    shiftCategories,
    specificTimes,
    startTime,
    endTime,
  } = watch('schedule')

  const [originalValues, setOriginalValues] = useState<AccessRuleForm>(defaultValues)
  const determineIfChangedFromOriginal = () => {
    if (!originalValues) {
      return false
    }
    const currentValues = getValues()
    return !areObjectsEqual(currentValues, originalValues)
  }

  watch(() => {
    setHasChangedFromOriginal(determineIfChangedFromOriginal())
  })

  const shifts = useMemo(
    () =>
      getOverlappingShifts(
        context.allShifts,
        {
          dateRange: { startDate, endDate, isInfinite },
          accessTimeType,
          selectedDays,
          restrictToShifts,
          shiftCategories,
          specificTimes,
          startTime,
          endTime,
        },
        selectedDate
      ),
    [
      context.allShifts,
      startDate,
      endDate,
      isInfinite,
      accessTimeType,
      selectedDays,
      restrictToShifts,
      shiftCategories,
      specificTimes,
      startTime,
      endTime,
      selectedDate,
    ]
  )

  const getRuleCloneName = (name: string): string => formatMessage(accessRulesMessages.cloneRuleName, { name })
  const cloneAccessRule = () => {
    if (!context.accessRule) {
      return
    }
    const copyName = getRuleCloneName(context.accessRule.name)
    const copyDateRange = getClonedDateRange({ startDate, endDate, isInfinite })
    const filteredAudienceTiers = context.accessRule?.audienceTiers.filter(audienceTier => !audienceTier.isEarlyAccess)
    setAccessRuleClone({
      ...context.accessRule,
      id: '',
      startDate: DateOnly.fromJsDateSafe(copyDateRange.startDate),
      startDateTime: DateTime.fromJsDateSafe(copyDateRange.startDate),
      endDate: DateOnly.fromJsDateSafe(copyDateRange.endDate),
      endDateTime: DateTime.fromJsDateSafe(copyDateRange.endDate),
      audienceTiers: filteredAudienceTiers,
    })
    setDecision('all')
    formMethods.setValue('name', copyName)
    formMethods.setValue('schedule.dateRange', copyDateRange)
    formMethods.setValue(
      'bookingChannels',
      getInitialBookingChannels({
        accessRule: { ...context.accessRule, audienceTiers: filteredAudienceTiers },
        audienceHierarchy: context.audienceHierarchy,
        defaultBookingChannels: accessRuleDefaults.bookingChannels,
        startOfDayTime: venueSettings.startOfDayTime,
        clientTagGroups: context.clientTagGroups,
      })
    )
    formMethods.setValue(
      'bookingWindow',
      getInitialBookingWindow({
        accessRule: { ...context.accessRule, audienceTiers: filteredAudienceTiers },
        defaultBookingWindow: accessRuleDefaults.bookingWindow,
      })
    )
  }

  const submitAsync = useCallback(
    async (data: AccessRuleInput) => {
      const { name } = data
      try {
        const response = await (!accessRuleClone && context.accessRule?.id
          ? saveAccessRule({
              args: { ...params, id: context.accessRule.id },
              data,
            })
          : createAccessRule({
              args: params,
              data,
            })
        ).unwrap()

        const overrides =
          response.startDate != null
            ? await getBookingAccessOverrideList({
                entityId: response.id,
                persistentId: response.persistentId,
                venueId: params.venueId,
                startDate: response.startDate.toIso(),
                endDate: response.endDate?.toIso(),
              }).unwrap()
            : []

        if (overrides.length > 0) {
          setARResponse(response)
          setIsOverridesModalOpen(true)
        } else {
          onRuleSave(response)
        }
        notify({
          content: formatMessage(accessRulesMessages.saveSuccess, { name }),
          type: 'success',
        })
      } catch {
        notify({
          content: formatMessage(accessRulesMessages.saveFailed, { name }),
          type: 'error',
        })
        setShowSpinner(false)
      }
    },
    [
      params,
      accessRuleClone,
      context.accessRule?.id,
      saveAccessRule,
      createAccessRule,
      getBookingAccessOverrideList,
      formatMessage,
      onRuleSave,
    ]
  )

  const submit = useCallback(
    (formData: AccessRuleForm) => {
      if ((!context.accessRule && mode !== 'new-item') || isMutating) {
        return
      }
      const prepData = toAccessRuleInput(
        selectedDate.toISOString(),
        params.venueId,
        venueSettings.isGoogleBookingEnabled,
        venueSettings.isTheforkIntegrationEnabled,
        decision
      )
      const data: AccessRuleInput = prepData(formData)
      if (props.context.accessRule && props.onRuleSaveOverride) {
        props.onRuleSaveOverride({ accessRuleInput: data, id: props.context.accessRule.id })
      } else {
        submitAsync(data)
        setShowSpinner(true)
      }
    },
    [
      context.accessRule,
      mode,
      isMutating,
      selectedDate,
      params.venueId,
      venueSettings.isGoogleBookingEnabled,
      venueSettings.isTheforkIntegrationEnabled,
      decision,
      props,
      submitAsync,
    ]
  )

  const deleteAsync = useCallback(
    async (accessRule: AccessRule, decision: Recurring) => {
      const { name } = accessRule
      try {
        await deleteAccessRule({
          from_date: selectedDate.toISOString(),
          entire_series: decision === 'all',
          is_recurring: ['all', 'following'].includes(decision),
          id: accessRule.id,
          venueId: params.venueId,
        }).unwrap()
        onRuleDelete()
        onClose()
        notify({
          content: formatMessage(accessRulesMessages.removeSuccess, { name }),
          type: 'success',
        })
      } catch {
        notify({
          content: formatMessage(accessRulesMessages.removeFailed, { name }),
          type: 'error',
        })
      }
    },
    [onRuleDelete, deleteAccessRule, selectedDate, params.venueId, onClose, formatMessage]
  )

  const onConfirmDelete = useCallback(async () => {
    if (!context.accessRule || !decision || isMutating) {
      return
    }
    deleteAsync(context.accessRule, decision)
    setDecision(undefined)
    setDecisionMode(undefined)
    setShowSpinner(true)
    setShowConfirmation(false)
  }, [context.accessRule, deleteAsync, isMutating, decision])

  const edit = useCallback(() => {
    if (shouldOverride) {
      setDecision('override')
    } else {
      setDecisionMode('edit')
    }
  }, [shouldOverride, setDecision, setDecisionMode])

  const onSelectDecision = (decision: Recurring) => {
    setDecision(decision)

    if (decisionMode !== 'delete') {
      if (decision === 'all') {
        const today = new Date()
        if (startDate && startDate <= today) {
          field.prop('schedule.dateRange.startDate').setQuietly(today)
          setOriginalValues({
            ...originalValues,
            schedule: {
              ...originalValues.schedule,
              dateRange: {
                ...originalValues.schedule.dateRange,
                startDate: today,
              },
            },
          })
        }
      } else if (decision === 'following') {
        field.prop('schedule.dateRange.startDate').setQuietly(selectedDate)
      } else if (decision === 'override') {
        field.prop('schedule.dateRange').setQuietly({
          startDate: selectedDate,
          endDate: selectedDate,
          isInfinite: false,
        })
        const overrideName = getOverrideName()
        field.prop('name').setQuietly(overrideName)
      }

      setDecisionMode(undefined)
      return
    }

    setShowConfirmation(true)
  }

  const onBeforeClose = useCallback(() => {
    if (hasChangedFromOriginal) {
      setIsUnsavedModalOpen(true)
    }
    return !hasChangedFromOriginal
  }, [hasChangedFromOriginal])

  useImperativeHandle(ref, () => ({ onBeforeClose }))

  const onDiscardDirtyChangesModal = useCallback(() => {
    setIsUnsavedModalOpen(false)
    setIsShow(false)
  }, [])

  const onDeleteActionClick = useCallback(() => {
    setDecisionMode('delete')
    if (isSameDay) {
      setDecision('override')
      setShowConfirmation(true)
    }
  }, [isSameDay])

  const confirmAndSubmit = useCallback(
    (formData: AccessRuleForm) => {
      setARFormData(formData)
      setIsReviewChangesModalOpen(true)
    },
    [setIsReviewChangesModalOpen, setARFormData]
  )

  const primaryAction = isEditOpen ? handleSubmit(confirmAndSubmit) : edit
  const secondaryAction = isEditOpen || associatedExclusiveAccessPerkId ? undefined : cloneAccessRule
  const deleteAction = onDeleteActionClick

  return (
    <SlideOut
      data-test={AccessRuleTestId.slideout}
      noTopPadding
      title={
        <HStack spacing="m">
          <Text textStyle="h2">
            {accessRuleClone ? getRuleCloneName(accessRuleClone.name) : name || formatMessage(accessRulesMessages.newAccessRule)}
          </Text>
          {context.accessRule?.id && (
            <Button variant="tertiary" data-test="view-activity-log" onClick={() => setIsActivityLogsActive(true)} noPadding>
              {formatMessage(accessRulesMessages.viewActivityLog)}
            </Button>
          )}
        </HStack>
      }
      show={isShow}
      background="secondaryBackground"
      onCloseComplete={props.onClose}
      onBeforeClose={onBeforeClose}
      footer={
        <AccessRuleSlideoutFooter
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          deleteAction={deleteAction}
          isEditOpen={isEditOpen}
          isFormDirty={hasChangedFromOriginal}
        />
      }
      enableBackdrop
    >
      {context.accessRule?.id && (
        <ActivityLogModal
          entityObjectId={context.accessRule.id}
          title={context.accessRule?.name || ''}
          isActive={isActivityLogsActive}
          setIsActive={setIsActivityLogsActive}
          onClose={() => setIsActivityLogsActive(false)}
        />
      )}
      {children}
      <RecurringDecision
        mode={decisionMode}
        active={((!decision && decisionMode !== undefined) || decisionMode === 'delete') && !isSameDay}
        setIsActive={v => {
          if (!v) {
            setDecisionMode(undefined)
          }
        }}
        isSelectedDayToday={isSelectedDayToday}
        selectedDate={selectedDate}
        name={context.accessRule?.name}
        onSuccess={onSelectDecision}
        setSelectedDate={(date: Date) => setSelectedDate(date)}
      />
      <ConfirmationDialog
        decision={decision as Recurring}
        active={showConfirmation}
        setIsActive={_isActive => {
          setShowConfirmation(!showConfirmation)
          setDecision(undefined)
          setDecisionMode(undefined)
        }}
        selectedDateOnly={selectedDateOnly}
        name={context.accessRule?.name}
        onSuccess={onConfirmDelete}
      />
      {showSpinner ? (
        <Loader />
      ) : (
        <AccessRuleContextProvider value={{ ...context, accessRule: accessRuleClone ?? context.accessRule, shifts }}>
          {isEditOpen ? (
            <FormProvider {...formMethods}>
              <EditMode field={field} isOverride={decision === 'override'} disabledFields={disabledFields} />
            </FormProvider>
          ) : (
            <ViewMode accessRule={defaultValues} />
          )}
        </AccessRuleContextProvider>
      )}
      <TransitionBlockerWithOldRouterSupport modal={<UnsavedChangesModal />} isBlocked={hasChangedFromOriginal} />
      <Window active={isUnsavedModalOpen}>
        <UnsavedChangesModal onCancel={() => setIsUnsavedModalOpen(false)} onDiscard={onDiscardDirtyChangesModal} />
      </Window>
      {arFormData && (
        <Window active={isReviewChangesModalOpen}>
          <ReviewChangesModal
            onDiscard={() => {
              setIsReviewChangesModalOpen(false)
              props.onClose()
            }}
            onCancel={() => setIsReviewChangesModalOpen(false)}
            onSave={() => {
              setIsReviewChangesModalOpen(false)
              submit(arFormData)
            }}
            name={name}
          >
            <AccessRuleChanges
              accessRuleData={arFormData}
              date={selectedDate.toISOString()}
              accessRuleId={context.accessRule?.id}
              decision={decision}
              hasBorder={false}
              introText={formatMessage(accessRulesMessages.preSaveChangesIntroText)}
            />
          </ReviewChangesModal>
        </Window>
      )}
      {bookingAccessOverrideList.isSuccess && (
        <Window active={isOverridesModalOpen}>
          <OverridesModal
            onClose={() => {
              setIsOverridesModalOpen(false)
              if (arResponse != null) {
                onRuleSave(arResponse)
              }
            }}
          >
            {arFormData && (
              <AccessRuleChanges
                accessRuleData={arFormData}
                date={selectedDate.toISOString()}
                accessRuleId={context.accessRule?.id}
                decision={decision}
                hasBorder
                introText={formatMessage(accessRulesMessages.postSaveChangesIntroText)}
              />
            )}
            <AccessRuleOverrides overrides={bookingAccessOverrideList.data} allShifts={context.allShifts} />
          </OverridesModal>
        </Window>
      )}
    </SlideOut>
  )
}
