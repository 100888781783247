import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useGetExperienceQuery } from '@sevenrooms/core/api'
import type { Experience } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, FormInput, Label, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, Loader, VStack, Breadcrumbs, UnsavedChangesModal } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { TransitionBlockerWithOldRouterSupport } from '../../../../../application/site/static/app/manager/lib/components/TransitionBlockerWithOldRouterSupport'
import { spacesMessages } from '../../spaces.locales'
import { AccessRules } from './AccessRules'
import { ExperiencesActionButtons } from './ActionButtons'
import { Details } from './Details'
import { Fees } from './Fees'
import { useSpaceForm, type SpaceFormType, getDefaultValues } from './Space.zod'
import { Status } from './Status'
import { useSubmit } from './useSubmit'

export function Space() {
  const { venueId } = useVenueContext()
  const nav = useNavigation()
  const { spaceId } = nav.matchParams(routes.manager2.marketing.groupBookings.editSpace) ?? {}
  const { data: experience, isLoading } = useGetExperienceQuery(spaceId ? { venueId, experienceId: spaceId } : skipToken)

  return isLoading ? <Loader /> : <SpaceForm experience={experience} />
}

interface SpaceFormProps {
  experience?: Experience
}

function SpaceForm({ experience }: SpaceFormProps) {
  const { formatMessage } = useLocales()
  const { venue, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const nav = useNavigation()
  const spaceId = experience?.id

  const createEditFormSchema = useSpaceForm()
  const form = useForm(createEditFormSchema, { defaultValues: getDefaultValues(experience), mode: 'onSubmit' })
  const {
    field,
    reset,
    resetField,
    formState: { dirtyFields, isDirty },
    handleSubmit,
  } = form

  const { onSubmit, isSubmitting } = useSubmit({ spaceId, dirtyFields, experience })
  const handleOnSubmit = useCallback(
    (isStay: boolean) => async (spaceForm: SpaceFormType) => {
      reset(spaceForm, { keepValues: true })
      const createdExperience = await onSubmit(spaceForm)
      if (!isStay) {
        nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })
      } else if (createdExperience && spaceId) {
        nav.push(routes.manager2.marketing.groupBookings.editSpace, {
          params: { venueKey, spaceId: createdExperience.id },
        })
      }
    },
    [reset, nav, onSubmit, spaceId, venueKey]
  )

  // eslint-disable-next-line no-console
  const handleOnInvalid = useCallback(err => console.error(err), [])

  const title = experience ? experience.name : formatMessage(spacesMessages.createSpace)

  return (
    <Box data-test="create-or-edit-space-page">
      <SettingsPageMeta venue={venue?.name} title={title} />
      <SettingsPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              data-test="space-title-button"
              variant="tertiary"
              noPadding
              onClick={() => nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })}
            >
              {formatMessage(spacesMessages.spacesTitle)}
            </Button>
            <Text>{title}</Text>
          </Breadcrumbs>
        }
        title={
          <HStack spacing="s">
            <Header type="h1">{title}</Header>
            <Status experience={experience} />
          </HStack>
        }
        actions={
          <ExperiencesActionButtons
            field={field}
            disabled={isSubmitting}
            experience={experience}
            onCancel={() => nav.push(routes.manager2.marketing.groupBookings, { params: { venueKey } })}
            onSave={isStay => handleSubmit(handleOnSubmit(isStay), handleOnInvalid)()}
          />
        }
      >
        {isSubmitting && <Loader />}
        <Block pl="lm" spacing="lm" pb="lm" hidden={isSubmitting}>
          <Details field={field} reset={() => resetField('description')} />
          <Fees field={field} />
          <AccessRules field={field} experience={experience} />
          {!!venueSettings?.is_triple_seat_dinewise_enabled && (
            <BaseSection title={formatMessage(spacesMessages.pdrTripleSeatIntegrationBoxTitle)}>
              <VStack spacing="lm" p="lm">
                <Label primary={formatMessage(spacesMessages.pdrTripleSeatRoomId)} />
                <FormInput field={field.prop('pdrTripleSeatRoomId')} />
              </VStack>
            </BaseSection>
          )}
          {!!venueSettings?.is_pdr_deposit_fee_enabled && (
            <BaseSection title={formatMessage(spacesMessages.pdrDepositFeeBoxTitle)}>
              <VStack spacing="lm" p="lm">
                <Label primary={formatMessage(spacesMessages.pdrDepositFee)} />
                <FormNumberInput field={field.prop('pdrDepositFee')} greaterThanZero max={100} />
              </VStack>
            </BaseSection>
          )}
        </Block>
      </SettingsPageContent>
      <TransitionBlockerWithOldRouterSupport modal={<UnsavedChangesModal />} isBlocked={isDirty} />
    </Box>
  )
}

// we need this because FroalaEditor became broken if we want to unmount it to show Loader
const Block = styled(VStack)<{ hidden: boolean }>`
  opacity: ${props => (props.hidden ? 0 : 1)};
`
