import { type Field, useFieldArray } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  FormInput,
  Label,
  Button,
  ImageUploader,
  FileUploader,
  FormMultiSelect,
  FormNumberInput,
  FormSelect,
} from '@sevenrooms/core/ui-kit/form'
import { Box, Grid, BaseSection, VStack, DividerLine, HStack } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, FormEditor } from '@sevenrooms/core/ui-kit/optional'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ExperienceFormEditorWrapper } from '@sevenrooms/mgr-offers/experiences/ExperienceFormEditorWrapper'
import { spacesMessages } from '../../spaces.locales'
import { amenitiesOptions, roomTypeOptions, unitsOfMeasurementOptions } from './selectOptions'
import type { SpaceFormType } from './Space.zod'

interface DetailsProps {
  field: Field<SpaceFormType>
  reset: () => void
}

export function Details({ field, reset }: DetailsProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()

  const { fields: images, append: appendImage, remove: removeImage } = useFieldArray(field, 'imageList')

  return (
    <BaseSection title={formatMessage(spacesMessages.spaceDetails)} description={formatMessage(spacesMessages.spaceDetailsDescription)}>
      <VStack spacing="lm" p="lm">
        <VStack spacing="s">
          <Label primary={formatMessage(spacesMessages.spaceName)} secondary={formatMessage(spacesMessages.spaceNameDescription)} />
          <FormInput
            field={field.prop('name')}
            data-test="name"
            placeholder={formatMessage(spacesMessages.spaceNamePlaceholder)}
            fullWidth
          />
        </VStack>

        <VStack>
          <Label
            primary={formatMessage(spacesMessages.descriptionBody)}
            secondary={formatMessage(spacesMessages.descriptionBodyDescription)}
          />
          <ExperienceFormEditorWrapper>
            <FormEditor
              field={field.prop('description')}
              config={{
                ...defaultConfig,
                heightMin: 100,
                toolbarButtons: [
                  'textColor',
                  'bold',
                  'italic',
                  'underline',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'formatUL',
                  'formatOL',
                  'insertLink',
                ],
                toolbarBottom: false,
                useClasses: false,
                events: {
                  initialized() {
                    // we need this to clean dirty state of FroalaEditor form field after default value set
                    reset()
                  },
                },
              }}
              data-test="description-body"
              isLoyaltyAndPerksEnabled={venueSettings?.is_loyalty_and_perks_enabled}
              referralProgramEnabled={venueSettings?.referral_program_enabled}
            />
          </ExperienceFormEditorWrapper>
        </VStack>

        <VStack>
          <Label primary={formatMessage(spacesMessages.mainImage)} secondary={formatMessage(spacesMessages.mainImageDescription)} />
          <ImageUploader field={field.prop('heroImage')} data-test="main-image" aspect={2} maxSize={2000000} unit="%" />
        </VStack>

        <VStack>
          <Text>{formatMessage(spacesMessages.additionalImageLabel)}</Text>
          {images.length > 0 && (
            <Grid gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))" gap="lm" gridAutoRows="min-content" alignItems="center">
              {images.map((image, idx) => (
                <ImageUploader
                  editText=""
                  deleteText=""
                  uploadText=""
                  variant="small"
                  onChange={img => !img && removeImage(idx)}
                  field={field.prop(`imageList.${idx}`)}
                  key={image.id}
                  alwaysShowDeleteButton
                  aspect={1.5}
                  unit="%"
                />
              ))}
            </Grid>
          )}
          <Box pt="lm">
            <Button
              data-test="add-image-button"
              variant="secondary"
              size="s"
              icon="VMSWeb-add"
              onClick={() => appendImage(null)}
              disabled={images.length === 8}
            >
              {formatMessage(spacesMessages.additionalImageAddButton)}
            </Button>
          </Box>
        </VStack>

        <DividerLine color="borders" ml="none" mr="none" />

        <HStack spacing="lm">
          <Label primary={formatMessage(spacesMessages.pdrMinSeatedCapacity)} width="100%">
            <FormNumberInput field={field.prop('pdrMinSeatedCapacity')} />
          </Label>
          <Label primary={formatMessage(spacesMessages.pdrMaxSeatedCapacity)} width="100%">
            <FormNumberInput field={field.prop('pdrMaxSeatedCapacity')} />
          </Label>
          <Label primary={formatMessage(spacesMessages.pdrMinStandingCapacity)} width="100%">
            <FormNumberInput field={field.prop('pdrMinStandingCapacity')} />
          </Label>
          <Label primary={formatMessage(spacesMessages.pdrMaxStandingCapacity)} width="100%">
            <FormNumberInput field={field.prop('pdrMaxStandingCapacity')} />
          </Label>
        </HStack>

        <HStack spacing="lm">
          <Label primary={formatMessage(spacesMessages.pdrRoomType)} width="100%">
            <FormSelect
              field={field.prop('pdrRoomType')}
              options={roomTypeOptions}
              placeholder={formatMessage(spacesMessages.pdrRoomTypePlaceholder)}
              withEmpty
            />
          </Label>
          <Label primary={formatMessage(spacesMessages.pdrSquareFootage)} width="100%">
            <FormNumberInput
              field={field.prop('pdrSquareFootage')}
              placeholder={formatMessage(spacesMessages.pdrSquareFootagePlaceholder)}
            />
          </Label>
          <Label primary={formatMessage(spacesMessages.pdrUnitOfMeasurement)} width="100%">
            <FormSelect
              field={field.prop('pdrUnitOfMeasurement')}
              options={unitsOfMeasurementOptions}
              placeholder={formatMessage(spacesMessages.pdrUnitOfMeasurementPlaceholder)}
              withEmpty
            />
          </Label>
        </HStack>

        <VStack>
          <Label primary={formatMessage(spacesMessages.pdrAmenities)} />
          <FormMultiSelect
            field={field.prop('pdrAmenityList')}
            placeholder={formatMessage(spacesMessages.pdrAmenitiesPlaceholder)}
            options={amenitiesOptions}
          />
        </VStack>

        <VStack maxWidth="50%">
          <Label primary={formatMessage(spacesMessages.menu)} secondary={formatMessage(spacesMessages.menuDescription)} />
          <FileUploader field={field.prop('menu')} hideDropZone />
        </VStack>
      </VStack>
    </BaseSection>
  )
}
